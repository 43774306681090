[data-custom-class='body'], [data-custom-class='body'] * {
  background: transparent !important;
}
[data-custom-class='title'], [data-custom-class='title'] * {
  font-family: Arial !important;
font-size: 26px !important;
color: #000000 !important;
}
[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
  font-family: Arial !important;
color: #595959 !important;
font-size: 14px !important;
}
[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
  font-family: Arial !important;
font-size: 19px !important;
color: #000000 !important;
}
[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
  font-family: Arial !important;
font-size: 17px !important;
color: #000000 !important;
}
[data-custom-class='body_text'], [data-custom-class='body_text'] * {
  color: #595959 !important;
font-size: 14px !important;
font-family: Arial !important;
}
[data-custom-class='link'], [data-custom-class='link'] * {
  color: #3030F1 !important;
font-size: 14px !important;
font-family: Arial !important;
word-break: break-word !important;
}


.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .message.global.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;
  }
  
  .message.global.noscript,
  .message.global.cookie {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1rem;
    text-align: justify;
    background: #ffee9c;
    border-color: #d6ca8e;
    color: #0a5a79;
    margin: 0;
    text-decoration: none;
  }
  
  .message.global p {
    margin: 0;
  }
  
  .message.global.noscript a,
  .message.global.cookie a {
    color: #0a5a79;
    text-decoration: none;
  }
  
  .message.global.cookie .actions {
    margin-top: 10px;
  }
  
  .action.primary {
    background: #00c9dc;
    color: white;
    font-size: 0.818rem;
    font-weight: bold;
    border-color: transparent;
    height: 3em;
    border-radius: 1.3rem;
    float: left;
    width: 30%;
  }
  
  .action.primary:hover {
    background: #0a5a79;
  }
  